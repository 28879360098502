import React from "react";
import Footer from "./Footer";
import "../css/privacy.css";
import Header from "./Header";

function Privacy() {
  return (
    <div className="main-container">
      <Header />
      <div className="mainBox" style={{ marginTop: "91px" }}>
        <div className="mainBox1" style={{ padding: "0 15%" }}>
          <div className="container-fluid">
            <div className="signpost1">
              <h1>プライバシーポリシー</h1>

              <p>
                TANYA UUOO
                LLC(以下「当社」といいます。)は、個人情報保護の重要性について認識し、個人情報の保護に
                関する法律(以下「個人情報保護法」といいます。)を遵守すると共に、以下のプライバシーポリシー(以下
                「本プライバシーポリシー」といいます。)に従い、適切な取扱い及び保護に努めます。なお、本プライバシ
                ーポリシーにおいて別段の定めがない限り、本プライバシーポリシーにおける用語の定義は、個人情報保
                護法の定めに従います。
              </p>
              <h2>1. 個人情報の定義</h2>
              <p>
                本プライバシーポリシーにおいて、個人情報とは、個人情報保護法第 2
                条第 1 項により定義される個人情報を意味するものとします。
              </p>
              <h2>2. 個人情報の利用目的</h2>
              <p>
                当社は、個人情報を以下の目的で利用いたします。
                <br />
                本サービスに関する各種事項の連絡や情報提供を行うため
                <br />
                本サービスを提供するため
                <br />
                お客さまのお申込みにかかる本サービス間の情報連携のため
                <br />
                本サービスに関するご請求、お支払いとその確認をするため
                <br />
                本サービスの利用状況等を調査、分析するため
                <br />
                本サービスの内容をよりご満足いただけるように改良、改善、又は新サービス開発のため
                <br />
                本サービスの内容をお客さまに合わせてカスタマイズするため
                <br />
                本サービスに関する満足度を調査するため
                <br />
                本サービス、新サービスその他各種サービスのご案内やお知らせをお届けするため
                <br />
                本サービス運営上のトラブル解決のため
              </p>

              <h2>3. 個人情報利用目的の変更</h2>
              <p>
                当社は、個人情報の利用目的を関連性を有すると合理的に認められる範囲内において変更することがあ
                り、変更した場合には個人情報の主体である個人(以下「本人」といいます。)に通知し又は公表します。
              </p>
              <br />
              <h2>4. 個人情報利用の制限</h2>
              <p>
                当社は、個人情報保護法その他の法令により許容される場合を除き、本人の同意を得ず、利用目的の達
                成に必要な範囲を超えて個人情報を取り扱いません。但し、次の場合はこの限りではありません。
                <br />
                法令に基づく場合
                <br />
                人の生命、身体又は財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき
                <br />
                公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき
                <br />
                国の機関もしくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに
                対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障
                を及ぼすおそれがあるとき
              </p>
              <h2>5. 個人情報の適正な取得</h2>

              <p>
                5.1
                当社は、適正に個人情報を取得し、偽りその他不正の手段により取得しません。
                <br />
                <br />
                5.2
                当社は、次の場合を除き、あらかじめ本人の同意を得ないで、要配慮個人情報(個人情報保護法第
                2 条第 3 項に定義されるものを意味します。)を取得しません。
                <br />
                第 4 項各号のいずれかに該当する場合
                <br />
                当該要配慮個人情報が、本人、国の機関、地方公共団体、個人情報保護法第
                76 条第 1
                項各号に掲げる者その他個人情報保護委員会規則で定める者により公開されている場合
                <br />
                本人を目視し、又は撮影することにより、その外形上明らかな要配慮個人情報を取得する場合
                <br />
                第7.1項但書によって第三者提供にあたらないものとされる態様にて要配慮個人情報の提供を受けるとき
                <br />
                5.3
                当社は、第三者から個人情報の提供を受けるに際しては、個人情報保護委員会規則で定めるとこ
                ろにより、次に掲げる事項の確認を行います。ただし、当該個人情報の提供が第
                4 項各号のいず れかに該当する場合又は第 7.1
                項但書によって第三者提供にあたらないものとされる態様でなさ
                れる場合を除きます。
                <br />
                当該第三者の氏名又は名称及び住所、並びに法人の場合はその代表者(法人でない団体で代表者又は管理人の定めのあるものの場合は、その代表者又は管理人)の氏名
                <br />
                当該第三者による当該個人情報の取得の経緯
              </p>
              <h2>6. 個人情報の安全管理</h2>
              <p>
                当社は、個人情報の紛失、破壊、改ざん及び漏洩などのリスクに対して、個人情報の安全管理が図られる
                よう、当社の従業員に対し、必要かつ適切な監督を行います。また、当社は、個人情報の取扱いの全部又
                は一部を委託する場合は、委託先において個人情報の安全管理が図られるよう、必要かつ適切な監督を
                行います。
              </p>
              <h2>7. 第三者提供</h2>
              <p>
                7.1 当社は、第 4
                項各号のいずれかに該当する場合を除くほか、あらかじめ本人の同意を得ないで、
                個人情報を第三者に提供しません。但し、次に掲げる場合は上記に定める第三者への提供には
                該当しません。
                <br />
                利用目的の達成に必要な範囲内において個人情報の取扱いの全部又は一部を委託することに伴って個人情報を提供する場合
                <br />
                合併その他の事由による事業の承継に伴って個人情報が提供される場合
                <br />第 8 項の定めに従って共同利用する場合
                <br />
                7.2 第 7.1 項の定めにかかわらず、当社は、第 4
                項各号のいずれかに該当する場合を除くほか、外国
                (個人情報保護法第24条に基づき個人情報保護委員会規則で指定される国を除きます。)にある
                第三者(個人情報保護法第 24
                条に基づき個人情報保護委員会規則で指定される基準に適合す
                る体制を整備している者を除きます。)に個人情報を提供する場合には、あらかじめ外国にある第
                三者への提供を認める旨の本人の同意を得るものとします。
                <br />
                7.3 当社は、個人情報を第三者に提供したときは、個人情報保護法第
                25 条に従い、記録の作成及び保存を行います。
                <br />
                7.4
                当社は、第三者から個人情報の提供を受ける場合には、個人情報保護法第26
                条に従い、必要な確認を行い、当該確認にかかる記録の作成及び保存を行うものとします。
              </p>
              <h2>8. 共同利用</h2>
              <p>
                当社は、以下のとおり個人情報を共同利用し、共同利用される個人情報を下記に定める利用者に提供いたします。
                <br />
                共同して利用される個人情報の項目
                <br />
                共同利用される情報は、取得情報となります。
                <br />
                共同して利用する者の範囲
                <br />
                利用目的の達成に必要な範囲で、取得情報を、弊社グループ各社間で共同利用いたします。
                <br />
                利用する者の利用目的
                <br />
                共同利用の目的は、「個人情報の利用目的について」記載のとおりです。
                <br />
                上記個人情報の管理について責任を有する者の氏名又は名称
                <br />
                共同利用における管理責任者は、TANYA UUOO
                LLCとなります。具体的なお問い合わせにつきましてはページ下部の「お問い合わせ」をご参照ください。
              </p>
              <h2>9. 個人情報の開示</h2>
              <p>
                当社は、本人から、個人情報保護法の定めに基づき個人情報の開示を求められたときは、本人ご自身か
                らのご請求であることを確認の上で、本人に対し、遅滞なく開示を行います(当該個人情報が存在しないと
                きにはその旨を通知いたします。)。但し、個人情報保護法その他の法令により、当社が開示の義務を負
                わない場合は、この限りではありません。
              </p>
              <h2>10. 個人情報の訂正等</h2>
              <p>
                当社は、本人から、個人情報が真実でないという理由によって、個人情報保護法の定めに基づきその内
                容の訂正、追加又は削除(以下「訂正等」といいます。)を求められた場合には、本人ご自身からのご請求
                であることを確認の上で、利用目的の達成に必要な範囲内において、遅滞なく必要な調査を行い、その結
                果に基づき、個人情報の内容の訂正等を行い、その旨を本人に通知します(訂正等を行わない旨の決定
                をしたときは、本人に対しその旨を通知いたします。)。但し、個人情報保護法その他の法令により、当社
                が訂正等の義務を負わない場合は、この限りではありません。
              </p>
              <h2>11. 個人情報の利用停止等</h2>
              <p>
                当社は、本人から、本人の個人情報が、あらかじめ公表された利用目的の範囲を超えて取り扱われている
                という理由又は偽りその他不正の手段により取得されたものであるという理由により、個人情報保護法の定
                めに基づきその利用の停止又は消去(以下「利用停止等」といいます。)を求められた場合、又は個人情報
                がご本人の同意なく第三者に提供されているという理由により、個人情報保護法の定めに基づきその提供
                の停止(以下「提供停止」といいます。)を求められた場合において、そのご請求に理由があることが判明し
                た場合には、本人ご自身からのご請求であることを確認の上で、遅滞なく個人情報の利用停止等又は提
                供停止を行い、その旨を本人に通知します。但し、個人情報保護法その他の法令により、当社が利用停止
                等又は提供停止の義務を負わない場合は、この限りではありません。
              </p>
              <h2>12. お問い合わせ</h2>
              <p>
                開示等のお申出、ご意見、ご質問、苦情のお申出その他個人情報の取扱いに関するお問い合わせは、下記の窓口までお願い致します。
                <br />
                <br /> TANYA UUOO LLC
              </p>
              <h2>14. 継続的改善</h2>
              <p>
                当社は、個人情報の取扱いに関する運用状況を適宜見直し、継続的な改善に努めるものとし、必要に応じて、本プライバシーポリシーを変更することがあります。
              </p>
              <br />
              <p style={{ textAlign: "center" }}>【2020年4月8日制定】</p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Privacy;

import React, { useContext, useRef, useState } from "react";
import Footer from "./Footer";
import "../css/main.css";
import "../css/mainFirst.css";
import productData from "../Datas/productData";
import { CartContext } from "../Component/CartContext";
import { Link } from "react-router-dom";

function Main() {
  const { toCheck } = useContext(CartContext);
  const contactRef = useRef(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [details, setDetails] = useState("");

  const toCheckOut = (id, name, price) => {
    toCheck(id, name, price);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const scrollToContact = () => {
    if (contactRef.current) {
      contactRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const contactOrder = (event) => {
    event.preventDefault();
    const newErrors = {};

    // 检查每个字段
    const cardInputs = document.querySelectorAll(
      'input[name="Name"], input[name="Email"], textarea[name="Message"]'
    );

    cardInputs.forEach((input) => {
      if (!input.value) {
        newErrors[input.name] = "この項目は必須です.";
        input.style.border = "2px solid red";
      } else {
        input.style.border = "";
      }
    });

    // 检查电子邮件格式
    const emailInput = document.querySelector('input[name="Email"]');
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailInput.value && !emailPattern.test(emailInput.value)) {
      newErrors["Email"] = "正しいメールアドレスを入力してください.";
      emailInput.style.border = "2px solid red";
    }

    if (Object.keys(newErrors).length > 0) {
      let errorMessage = "すべての必須項目を正しく記入してください:\n";
      for (const key in newErrors) {
        errorMessage += `${newErrors[key]}\n`;
      }
      alert(errorMessage);
    } else {
      alert("送信成功!");
      setName("");
      setEmail("");
      setDetails("");
    }
  };

  return (
    <div className="main-container">
      <div className="header-container">
        <div className="header">
          <div className="header__inner__left">
            <p>INTERICO</p>
          </div>
          <div className="header__inner">
            <div className="wrp-navi">
              <Link to="/" onClick={toTop}>
                ホームページ
              </Link>
              <Link to="/" onClick={scrollToContact}>
                お問い合わせ
              </Link>
              <Link to="/privacy" onClick={toTop}>
                プライバシーポリシー
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="mainBox">
        <div className="transparent-div">
          <img
            height="900px"
            style={{ width: "100%" }}
            alt=""
            src="/head/main.webp"
          />
          <div className="block-mv04_txt">
            <img width="473" height="145" alt="" src="/head/main1.webp" />
          </div>
        </div>
        <div className="mainBox1" style={{ padding: "7% 13% 0 13%" }}>
          <div className="base-sub">
            <p className="base_p1">INTERICOって？</p>
            <h1 className="base_h1">
              プロのインテリアコーディネーターが
              <br />
              オンラインで理想の暮らしを実現します。
            </h1>
            <p className="base_p2">
              1. 隙間時間でインテリアコーディネートの依頼から手配までお任せ
              <br />
              2. TVにも出演のコーディネート事例多数のインテリア専門会社が運営
              <br />
              3. 審査を通った若手実力派のコーディネーターが担当します
            </p>
          </div>
        </div>

        <div
          className="mainBox1"
          style={{
            padding: "1% 13%",
            backgroundColor: "rgba(231, 224, 215, 0.35)",
          }}
        >
          <div className="base-sub">
            <p className="base1_p1">
              インテリアコーディネートって
              <br />
              どんな時に、依頼するの？
            </p>
            <h3 className="base1_h3">
              こんなお悩みを抱えている方に、オススメです。
            </h3>
            <p className="base1_p2">
              インテリアコーディネートって敷居が高い・・
              <br />
              お部屋をオシャレにしたいけど、何を買えばいいか分からない。
              <br />
              今のお部屋や持ってる家具に合う家具が分からない。
              <br />
              家具を自分で考えたり探したり揃えたりするのが面倒。
              <br />
              家具の組み合わせやレイアウトを、どうしていいか分からない。
              <br />
              家具が沢山ありすぎて、選べられない。
              <br />
              予算やなりたいイメージを考慮した家具を探すのが大変・・。
              <br />
            </p>
          </div>
        </div>

        <div className="sd_div" style={{ padding: "7% 13% 4% 13%" }}>
          <div className="sd__inner">
            <h2>インテリアコーディネーターに依頼すると、どう違うの？</h2>
            <h3>インテリアを通して、理想のライフスタイルが実現できます。</h3>
            <div className="sd_inner_flex">
              <div className="sd_flex">
                <h4>理想の暮らしが手に入る</h4>
                <div className="sd_flex_img">
                  <img
                    alt=""
                    src="https://images.unsplash.com/photo-1586023492125-27b2c045efd7?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=Mnw2MzQ2fDB8MXxzZWFyY2h8M3x8aW50ZXJpb3J8ZW58MHx8fHwxNjI0NzUwMzk5&ixlib=rb-1.2.1&q=80&w=1080"
                  />
                </div>
                <p>
                  毎日身近に触れる家具だからこそ、見た目の美しさだけではなく、使いやすさや統一感も大事。理想の暮らしと現状のお部屋や予算を踏まえたインテリアコーディネートで、お家で過ごす時間がとっても快適になります。
                </p>
              </div>
              <div className="sd_flex">
                <h4>理想の暮らしが手に入る</h4>
                <div className="sd_flex_img">
                  <img
                    alt=""
                    src="https://storage.googleapis.com/studio-design-asset-files/projects/6kq919EXqp/s-2400x1600_v-frms_webp_465c873f-ffd6-4936-9dca-af5bd1d2182a_small.webp"
                  />
                </div>
                <p>
                  毎日身近に触れる家具だからこそ、見た目の美しさだけではなく、使いやすさや統一感も大事。理想の暮らしと現状のお部屋や予算を踏まえたインテリアコーディネートで、お家で過ごす時間がとっても快適になります。
                </p>
              </div>
              <div className="sd_flex">
                <h4>理想の暮らしが手に入る</h4>
                <div className="sd_flex_img">
                  <img
                    alt=""
                    src="https://storage.googleapis.com/studio-design-assets/projects/6kq919EXqp/s-1934x1846_v-frms_webp_664b04e0-f2e4-4d3e-abc5-cda43e88371e_small.webp"
                  />
                </div>
                <p>
                  毎日身近に触れる家具だからこそ、見た目の美しさだけではなく、使いやすさや統一感も大事。理想の暮らしと現状のお部屋や予算を踏まえたインテリアコーディネートで、お家で過ごす時間がとっても快適になります。
                </p>
              </div>
            </div>
          </div>
        </div>

        <div
          className="appear"
          style={{
            padding: "1% 13%",
            backgroundColor: "rgba(231, 224, 215, 0.35)",
          }}
        >
          <div className="appear_inner">
            <h2>オンラインで簡単に理想のインテリアを</h2>
            <p>
              ※個人で申し込む方の料金になります。法人の方はお問い合わせ下さい。
            </p>
            <div className="appear_good_div">
              {productData.map((room, index) => (
                <div className="appear_good">
                  <p className="limit_p">月２０件限定</p>
                  <h2>{room.name}</h2>

                  <div className="price_good" key={index}>
                    <p>¥</p>
                    <p>{room.price}~</p>
                    <p>(+tax)</p>
                    <p>/ 1部屋</p>
                  </div>

                  <p style={{ textAlign: "left" }}>
                    お部屋、お好み、ご予算、生活スタイルなどに沿った、あなただけのコーディネートをご提案します♪
                  </p>
                  <div className="check_p">
                    <p>
                      <img
                        width="18"
                        height="18"
                        src="https://img.icons8.com/material-outlined/24/1A1A1A/checkmark.png"
                        alt="checkmark"
                      />
                      LINEでヒアリング
                    </p>
                    <p>
                      <img
                        width="18"
                        height="18"
                        src="https://img.icons8.com/material-outlined/24/1A1A1A/checkmark.png"
                        alt="checkmark"
                      />
                      インテリアコーディネート作成
                    </p>
                    <p>
                      <img
                        width="18"
                        height="18"
                        src="https://img.icons8.com/material-outlined/24/1A1A1A/checkmark.png"
                        alt="checkmark"
                      />
                      修正は、１回までご対応
                    </p>
                    <p>
                      <img
                        width="18"
                        height="18"
                        src="https://img.icons8.com/material-outlined/24/1A1A1A/checkmark.png"
                        alt="checkmark"
                      />
                      コーディネート資料と見積書を送付します
                    </p>
                  </div>

                  <div className="check_div">
                    <Link
                      to="/checkout"
                      className="btn"
                      onClick={() => toCheckOut(room.id, room.name, room.price)}
                    >
                      購入
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="sd_div" style={{ padding: "7% 13% 4% 13%" }}>
          <div className="use_sd">
            <h2>
              オンラインインテリアコーディネート
              <br />
              ご利用の流れ
            </h2>
            <p>
              インテリアコーディネーターが、ご予算ご希望に合ったコーディネートをご提案。{" "}
              <br />
              インテリアコーディネートから家具の購入まで、すべてオンラインで完結。{" "}
              <br />
              コーディネートの修正は１回までご対応いたします。
            </p>
            <div className="link_sd">
              <h3>１　LINEで友達追加</h3>
            </div>
            <div className="link_sd">
              <h3>２　ヒアリングシートをご記入</h3>
              <p>
                LINE
                でお送りします。お部屋の間取り/お好みのインテリアスタイル/ご予算/お部屋の完成希望時期などを伺います！
                <br />
                ※お部屋の寸法がわかる間取り画像、写真をご用意ください。
              </p>
              <Link to="/">
                オンラインインテリアコーディネートで気をつけるポイント
              </Link>
            </div>
            <div className="link_sd">
              <h3>３　お支払い</h3>
              <p>
                インテリアコーディネートの請求書を送付いたします。
                <br />
                お手数ですが、お振込にてお願いいたします。
                <br />
                （クレジットカード支払いは、現在調整中です。）
              </p>
            </div>
            <div className="link_sd">
              <h3>４　詳細ヒアリング</h3>
              <p>
                ご希望のお部屋にしていくために、ヒアリングシートをもとに、担当のインテリアコーディネーターが質問をさせていただきます。
                <br />
                この時に、ご要望やご心配ことなどございましたら、お気軽にコーディネーターにお伝えください♪
              </p>
            </div>

            <div className="link_sd">
              <h3>5　ご提案</h3>
              <p>
                「お部屋のレイアウト、インテリアイメージ、アイテムの詳細」と「アイテムのお見積書」をご提出させていただきます。
                <br />
                ※ご提案アイテムを購入する場合、商品到着日の調整をさせていただきます。
              </p>
            </div>
            <div className="link_sd">
              <h3>6　家具手配</h3>
              <p>
                購入アイテムが決まりましたら、家具の請求書を送付いたします。
                <br />
                お手数ですが、お振込にてお願いいたします。
                <br />
                確認後、家具類の手配を行います。
              </p>
            </div>
            <div className="link_sd">
              <h3>７　完成</h3>
              <p>商品のお受け取りをお願いいたします。</p>
            </div>
          </div>
        </div>

        <div
          className="appear"
          style={{
            padding: "1% 13%",
            backgroundColor: "rgba(231, 224, 215, 0.35)",
          }}
        >
          <div className="exemple_sd">
            <h2>LINEでのご提案の例</h2>
            <p>
              お部屋のインテリアスタイルがわかるムードボード、配置や組み合わせイメージ、コーディネートの説明、購入リンクをお送りします。
              <br />
              ご不明な点や修正して頂きたい部分がございましたら、LINEでお気軽にご相談ください。
            </p>
            <div className="exemple_img">
              <img
                width="1000"
                height="763"
                alt=""
                src="https://storage.googleapis.com/studio-design-assets/projects/6kq919EXqp/s-2400x1832_v-frms_webp_e20827bb-9b21-4036-a02e-811c96ff9f69_middle.webp"
              />
            </div>
            <h2>インテリアコーディネーター</h2>
            <h3>
              INTERICOのコーディネーターは実際にインテリアのお仕事を第一線でこなしているプロです。
              <br />
              厳しい選考を通過した確かな実力と豊かな経験を持つコーディネーターたちで構成されています。
            </h3>
            <div className="person_imgs">
              <div className="person_sd">
                <div>
                  <img
                    alt=""
                    src="https://storage.googleapis.com/studio-design-asset-files/projects/6kq919EXqp/s-579x531_webp_5b06f498-905d-4362-9c08-bba3491f9fd2.webp"
                  />
                  <p>林　佑実</p>
                </div>
              </div>
              <div className="person_sd">
                <div>
                  <img
                    alt=""
                    src="https://storage.googleapis.com/studio-design-assets/projects/6kq919EXqp/s-1282x1447_v-fms_webp_65ae9622-1fb5-499e-93d3-f6f63cb8920f_small.webp"
                  />
                  <p>記野　亜季</p>
                </div>
              </div>
              <div className="person_sd">
                <div>
                  <img
                    alt=""
                    src="https://storage.googleapis.com/studio-design-asset-files/projects/6kq919EXqp/s-859x859_v-fs_webp_5f249de4-76ec-4e39-98ff-a26817ac0970_small.webp"
                  />
                  <p>田口　りな</p>
                </div>
              </div>
              <div className="person_sd">
                <div>
                  <img
                    alt=""
                    src="https://storage.googleapis.com/studio-design-asset-files/projects/6kq919EXqp/s-859x859_v-fs_webp_5f249de4-76ec-4e39-98ff-a26817ac0970_small.webp"
                  />
                  <p>芝　郁美</p>
                </div>
              </div>

              <div className="person_sd">
                <div>
                  <img
                    alt=""
                    src="https://storage.googleapis.com/studio-design-asset-files/projects/6kq919EXqp/s-960x960_v-fs_webp_cdbb446c-6263-435b-bc86-8e123e16904e_small.webp"
                  />
                  <p>佐藤　愛美</p>
                </div>
              </div>
              <div className="person_sd">
                <div>
                  <img
                    alt=""
                    src="https://storage.googleapis.com/studio-design-assets/projects/6kq919EXqp/s-880x930_v-fs_webp_a6fb562e-5a16-4d5b-92dc-79cc78bbd7a3_small.webp"
                  />
                  <p>島　ひかる</p>
                </div>
              </div>
              <div className="person_sd">
                <div>
                  <img
                    alt=""
                    src="https://storage.googleapis.com/studio-design-assets/projects/6kq919EXqp/s-1070x1062_v-fs_webp_e6af239e-7090-4582-9191-ed52e6332b65_small.webp"
                  />
                  <p>本多　摩美</p>
                </div>
              </div>
              <div className="person_sd">
                <div>
                  <img
                    alt=""
                    src="https://storage.googleapis.com/studio-design-assets/projects/6kq919EXqp/s-2104x2131_v-frms_webp_e7189279-edd6-4c4c-9c29-9ce44a18e248_small.webp"
                  />
                  <p>吉.岡. 智之</p>
                </div>
              </div>
            </div>

            <h3>他にも、実力のあるコーディネーターが多数在籍しております。</h3>
          </div>
        </div>

        <div className="sd_div" style={{ padding: "2% 13% 4% 13%" }}>
          <div className="notice_sd">
            <h2>お知らせ</h2>
            <div className="notice__pp">
              <p>
                2020 7/11
                <br />
                インテリアビジネスニュースに当サービスがご紹介されました。
              </p>
              <p>
                2020 6/23
                <br />
                「zoom interior」から「INTERICO」にサービス名を変更しました。
              </p>

              <p>
                2020 5/29
                <br />
                5/29のFM神戸 Viva la radioにて『zoom
                interior』について紹介されました。
              </p>
              <p>
                2020 5/19
                <br />
                TRILLにて『zoom interior』について紹介されました。
              </p>
              <p>
                2020 5/8
                <br />
                読売新聞オンラインにて『zoom interior』について紹介されました。
              </p>
            </div>
          </div>
        </div>

        <div
          className="appear"
          style={{
            padding: "1% 13% 4% 13%",
            backgroundColor: "rgba(231, 224, 215, 0.35)",
          }}
          ref={contactRef}
        >
          <div className="contact_sd">
            <p>お問い合わせ</p>
            <div className="contact_form_div">
              <form name="form1" className="sd_appear">
                <div tabIndex="0" className="form_all_div">
                  <p className="text sd appear">
                    名前
                    <br />
                  </p>
                  <input
                    name="Name"
                    placeholder="Your Name"
                    required
                    type="text"
                    autoComplete="off"
                    className="sd appear"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div tabIndex="0" className="form_all_div">
                  <p className="text sd appear">
                    メールアドレス
                    <br />
                  </p>
                  <input
                    name="Email"
                    placeholder="Email Address"
                    required
                    type="email"
                    autoComplete="off"
                    className="sd appear"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div tabIndex="0" className="form_all_div">
                  <p className="text sd appear">
                    お問い合わせ内容
                    <br />
                  </p>
                  <textarea
                    name="Message"
                    placeholder="Put message here"
                    required
                    className="sd appear"
                    value={details}
                    onChange={(e) => setDetails(e.target.value)}
                  />
                </div>
                <button
                  type="submit"
                  className="button_sd"
                  onClick={contactOrder}
                >
                  <p className="text sd appear">送信する</p>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Main;

const productData = [
  {
    id: 1,
    name: "１６畳のリビングと寝室",
    price: "35000",
  },
  {
    id: 2,
    name: "２１畳のリビングダイニングと子供部屋の場合",
    price: "37000",
  },
];

export default productData;

import React from "react";
import { Link } from "react-router-dom";
import "../css/header.css";

function Header() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="header-container">
      <div className="header">
        <div className="header__inner__left">
          <p>INTERICO</p>
        </div>
        <div className="header__inner">
          <div className="wrp-navi">
            <Link to="/" onClick={toTop}>
              ホームページ
            </Link>
            <Link to="/" onClick={toTop}>
              お問い合わせ
            </Link>
            <Link to="/privacy" onClick={toTop}>
              プライバシーポリシー
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;

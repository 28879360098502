import React from "react";
import Footer from "./Footer";
import "../css/privacy.css";
import Header from "./Header";

function Particular() {
  return (
    <div className="main-container">
      <Header />
      <div className="mainBox" style={{ marginTop: "91px" }}>
        <div className="mainBox1" style={{ padding: "0 20%" }}>
          <div className="container-fluid">
            <div className="signpost1">
              <h1>特定商取引法に基づく表示</h1>
              <br />
              <h2>販売価格</h2>
              <p>
                コーディネートサービスプランごと、またご依頼の部屋の平米数ごとに表示致します。
              </p>
              <h2>代金のお支払い時期</h2>
              <p>サービス依頼都度、チャット上にて決済URLを送付いたします。</p>
              <h2>サービスの引渡し時期</h2>
              <p>zoomでのヒアリングから１０日前後でご提供致します。</p>
              <h2>事業者・連絡先</h2>
              <p>
                事業会社: TANYA UUOO LLC
                <br />
                代表者:島ひかる
                <br />
                所在地:1309 Coffeen Avenue STE 1200，Sheridan, Wyoming 82801
                <br />
                メールアドレス: study@raltao.buzz
                <br />
                電話番号: (415) 425-9345
              </p>
              <h2>販売価格以外でお客様に発生する金銭</h2>
              <p>
                当サイトのページの閲覧、コンテンツ購入、ソフトウェアのダウンロード等に必要となるインターネット接続料金、通信料金は、お客様のご負担となります。
              </p>
              <h2>返品・キャンセルについて</h2>
              <p>
                実際にサービスをご利用する前までは、注文のキャンセルは可能です。
                <br />
                商品の性質上、サービスご利用後の返金・返品はできかねます。
                <br />
                あらかじめ利用規約、サービス内容をよくお確かめの上、お申込みをお願い致します。
              </p>
              <br />
              <p style={{ textAlign: "center" }}>【2020年4月8日制定】</p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Particular;

import React from "react";
import "../css/footer.css";
import { Link } from "react-router-dom";

function Footer() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <div className="bottom-container">
        <p>
          INTERICO（インテリコ） は、プロのインテリアコーディネーター
          にオンラインでインテリアの相談ができるサービスです。
          <br />
          オンラインでインテリアコーディネートが全て完結します！ご要望をお伺いし、一部屋¥2,5000円~でお部屋のトータルコーディネートをいたします。
          <br />
          あなたにぴったりなインテリアコーディネーターがあなたらしいお部屋づくりをサポートいたします。提案商品は全てネットで購入可能です。
          <br />
          個人でも気軽にインテリアコーディネートを楽しめるように。
        </p>
        <div className="base">
          <dl className="block-table03">
            <div className="wrp-img">
              <Link to="/" onClick={toTop}>
                <img
                  width="220"
                  height="54"
                  src="https://storage.googleapis.com/studio-design-assets/projects/6kq919EXqp/s-542x133_webp_45194d12-0691-4000-b6e6-09c93d4fac25.webp"
                  alt=""
                />
              </Link>
            </div>
            <div className="wrp-navi">
              <ul className="wrp-txt">
                <li className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-29">
                  <Link to="/" aria-current="page" onClick={toTop}>
                    HOME
                  </Link>
                </li>
                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-30">
                  <Link to="/privacy" onClick={toTop}>
                    プライバシーポリシー
                  </Link>
                </li>
                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-642">
                  <Link to="/particular" onClick={toTop}>
                    特定商取引法の表記
                  </Link>
                </li>
              </ul>
              <ul className="sns"></ul>
            </div>
          </dl>
        </div>
        <div className="botton_fff">
          <p>
            2023 © INTERICO【オンライン インテリアインテリアコーディネート】
          </p>
        </div>
      </div>
    </>
  );
}

export default Footer;
